import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Code = styled.h1`
  font-size: 14rem;
`
const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not found" />
    <div className="container mx-auto flex justify-around mt-16 lg:mt-32 overflow-hidden">
      <div className="text-gray-700">
        <h2 className="text-3xl font-semibold">Page not found</h2>
        <p className="text-xl my-8 font-medium">
          Sorry, we couldn&#39;t find that page... Let's get you somewhere
          familiar.
        </p>
        <div>
          <Link to="/">Home</Link>
        </div>
        <div>
          <Link to="/sign-up">Sign Up</Link>
        </div>
        <div>
          <Link to="/faq">Frequently Asked Questions</Link>
        </div>
        <div className="text-sm mt-4 font-semibold text-gray-700">
          Having any issues? <br /> Call us at{" "}
          <a className="text-blue-500" href="tel:303-542-7110">
            (303) 542 - 7110
          </a>{" "}
          or email{" "}
          <a
            className="text-blue-500"
            href="mailto:info@archerhub.com?Subject=Enquiry"
            target="_top"
          >
            support@archerhub.com
          </a>
        </div>
      </div>
      <div>
        <Code className="text-center text-gray-700 font-hairline" title="404">
          404
        </Code>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
